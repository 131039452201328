export const i18nDynamicStrings = {
  spoilerCreate: {
    searchMoviePlaceholder: 'Busca una película...',
    searchTVShowPlaceholder: 'Busca una serie...',
    searchEpisodePlaceholder: 'Busca un episodio...',
    searchDefaultPlaceholder: 'Buscar...',
    spoilerTextareaPlaceholder: 'Ecribe tu spoiler aquí...'
  },
  flashMessages: {
    spoilerCreated:
      'Gracias pour haber contribuido a la base de datos Spoil Me. Puedes añadir otros spoilers si te acuerdas de más !',
    errorSpoilerCreation: `Un error ha occurrido`
  },
  header: {
    addSpoilerFor: 'Añade un spoiler para',
    addSpoiler: 'Añade un spoiler'
  },
  misc: {
    season: 'Temporada',
    movies: 'Películas',
    tvShows: 'Series'
  }
}
