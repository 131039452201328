// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  language: 'es',
  translationUrls: {
    en: 'https://preprod.spoilme.io',
    fr: 'https://preprod.fr.spoilme.io',
    es: 'https://preprod.es.spoilme.io'
  },
  baseUrl: 'https://preprod.es.spoilme.io',
  apiBaseUrl: 'https://preprod.api.spoilme.io',
  bugsnagApiKey: 'bc41d8af214d65380e90097c23e33e10'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
