import { WorkType } from '../app/common/enums/work-type.enum'
import { Episode } from '../app/common/interfaces/episode.interface'
import { MetaObject } from '../app/common/interfaces/meta-object.interface'
import { Movie } from '../app/common/interfaces/movie.interface'
import { Season } from '../app/common/interfaces/season.interface'
import { TvShow } from '../app/common/interfaces/tv-show.interface'

export const i18nMeta = {
  getMovieDetailMeta: (m: Movie, idAndSlug: string): MetaObject => ({
    path: `/movies/${idAndSlug}`,
    title: `Spoilers De La Película ${m.name}`,
    description: `Spoilers de "${m.name}". El Final o la Trama de la Película en 100 carácteres (SPOILER).`
  }),
  getTvShowDetailMeta: (t: TvShow, idAndSlug: string): MetaObject => ({
    path: `/tv-shows/${idAndSlug}`,
    title: `Spoilers de la Serie ${t.name}`,
    description: `Todos los spoilers de la serie "${t.name}" (${t.seasons.length} temporadas). Spoilers episodio por episodio gracias a la communidad Spoil Me.`
  }),
  getSeasonDetailMeta: (s: Season, tvShowIdAndSlug: string): MetaObject => ({
    path: `/tv-shows/${tvShowIdAndSlug}/seasons/${s.number}`,
    title: `Spoilers De La Temporada ${s.number} de la serie "${s.tvShow.name}"`,
    description: `Todos los spoilers de la temporada ${s.number} la serie "${s.tvShow.name}". Spoilers episodio por episodio gracias a la communidad Spoil Me.`
  }),
  getEpisodeDetailMeta: (
    e: Episode,
    tvShowIdAndSlug: string,
    episodeNumberAndSlug: string
  ): MetaObject => ({
    path: `/tv-shows/${tvShowIdAndSlug}/seasons/${e.season.number}/episodes/${episodeNumberAndSlug}`,
    title: `Spoilers de ${e.season.tvShow.name} ${e.name}`,
    description: `Todos los spoilers del episodio ${e.name} la temporada ${e.season.number} la serie "${e.season.tvShow.name}" en Spoil Me.`
  }),
  getWorkIndexMeta: (workType: WorkType, firstLetter: string): MetaObject => {
    if (workType === WorkType.Movie) {
      return {
        path: `/movies/index/${firstLetter}`,
        title: `${firstLetter} películas en Spoilme`,
        description: `${firstLetter} películas en Spoilme`
      }
    }
    if (workType === WorkType.TvShow) {
      return {
        path: `/tv-shows/index/${firstLetter}`,
        title: `${firstLetter} series en Spoilme`,
        description: `${firstLetter} series en Spoilme`
      }
    }
  },
  static: {
    homeMovies: {
      title: `La Primera Base de Spoilers de Películas`,
      description:
        'La Primera Base de Spoilers de Películas. Destroza cualquier película con el buscador de Spoilers Spoil Me',
      path: ''
    },
    homeTvShows: {
      title: `La Primera Base de Spoilers de Series`,
      description: `La Primera Base de Spoilers de Series. Destroza cualquier episodio de serie con el buscador de Spoilers Spoil Me`,
      path: 'tv-shows'
    },
    spoilerCreate: {
      title: 'Añade tu spoiler',
      description:
        'Contribuye à la primera base de datos mundial de Spoilers destrozando las películas que has visto !',
      path: '/movies/spoilers/create'
    },
    privacyLegacy: {
      title: `Privacy Legacy`,
      path: 'privacy-legacy'
    },
    error404: {
      title: `Error 404 : Page not found`,
      path: '404'
    }
  }
}
